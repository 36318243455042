<template>
	<el-dialog class="editDialog" :title="dialogObj.title" :visible.sync="visiable" width="70%">
		<div class="orderInfo" style="display: flex;">
			<div style="width: 200px;text-align:center;border-right:1px solid #ddd ;">
				<img src="@assets/osImg/order/yanshou.png" style="width:90px;height:90px" />
				<div style="font-size:18px;font-weight: bold; color: #4a99f6;">{{tabObj[data.orderStatus]}}</div>
			</div>
			<div style="flex:1;margin-left: 20px;display: flex;flex-direction: column;">
				<div style="flex:1;display: flex;">
					<div style="flex: 1;">
						<div style="display: inline-block;margin-bottom: 20px;">订单编号：{{data.orderNo}}</div>
						<div style="display: inline-block;margin-left: 30px;">下单时间：{{data.orderTime}}</div>
						<div style="">付款方式：转账汇款</div>
					</div>
					<div v-if="dialogObj.isEdit" style="margin-right: 30px;">
						<el-button type="primary" @click="confirmOrder">确认订单</el-button>
						<el-button type="primary" plain @click="cancelOrder">取消订单</el-button>
					</div>
				</div>
				<div>

					<el-table :data="[data]" border style="margin-top: 30px;" v-if="!dialogObj.isEdit">
						<el-table-column prop="payDeadline" label="付款截止时间">
						</el-table-column>
						<el-table-column prop="actualPrice" label="付款金额">
						</el-table-column>
						<el-table-column prop="payStatus" label="状态">
							<template slot-scope="scope">
								<div v-if="scope.row.payStatus==0">
									未支付
								</div>
								<div v-if="scope.row.payStatus==1">
									已支付
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="requireState" label="说明">
						</el-table-column>
					</el-table>
				</div>
			</div>
		</div>
		<div class="serviceInfo" style="margin-top: 20px;">
			<el-table :data="[data]" border>
				<el-table-column prop="date" label="服务信息">
					<template slot-scope="scope">
						<img :src="imgBaseUrl+record.image" style="width: 100px;height: 100px;" />
						<div style="display: inline-block;position: absolute;left: 127px;top: 24px;">
							<div style="font-weight: bold;margin-bottom: 10px;font-size: 16px;">
								{{scope.row.serviceName}}
							</div>
							<div>{{scope.row.expertName}}·{{scope.row.city}}</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="requireTime" label="要求完成时间" width="200px">
				</el-table-column>
				<el-table-column prop="servicePrice" label="价格" width="150px">
				</el-table-column>
				<el-table-column prop="servicePrice" label="小计" width="150px">
				</el-table-column>
				<el-table-column prop="servicePrice" label="实付" width="200px">
					<template slot-scope="scope">
						<div v-if="dialogObj.isEdit">
							<el-input v-model="editPrice" style="width: 150px;"> <template slot="prepend">￥</template>
							</el-input>
						</div>
						<div v-else>
							￥{{scope.row.servicePrice}}
						</div>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<div class="payInfo"
			style="display: flex;margin-top: 30px;background-color: #efeff4;border-radius: 5px;padding:0.27778rem">
			<div class="payInfo-left" style="flex:1">
				<div>
					<span
						style="font-size: .2963rem;font-weight: 600;padding-right: .37037rem;color: #262626;">支付方式</span><span>目前支持对公转账</span>
				</div>
				<div>户名：{{data.bandUserName}}</div>
				<div>开户行：{{data.bandDeposit}}</div>
				<div>账号：{{data.bandAccount}}</div>
			</div>
			<div class="payInfo-right" style="flex:1">
				<div style="font-size: .2963rem;font-weight: 600;padding-right: .37037rem;color: #262626;">工作地址</div>
				<div>收货人：{{data.receiver}}</div>
				<div>联系方式：{{data.mobile}}</div>
				<div>收货地址：{{data.addressFull}}</div>
			</div>
		</div>
		<div class="orderDetail"
			style="margin-top: 30px;background-color: #efeff4;border-radius: 5px;padding:0.27778rem;min-height: 150px;">
			<div
				style="font-size: .2963rem;font-weight: 600;padding-right: .37037rem;color: #262626;margin-bottom: 10px;">
				需求详细描述
			</div>
			<div>{{data.requireState}}</div>
		</div>
		<div class="totalPrice"
			style="margin-top: 30px;background-color: #f2fbff;border: .01852rem solid #bae7ff;height: 150px;display: flex;">
			<div style="flex:1"></div>
			<div style="width:3.7037rem;vertical-align: middle;margin: auto 0;">
				<div>服务总价：￥<span style="color: #4a99f6;">{{data.servicePrice}}</span></div>
				<div>应付金额：￥<span
						style="font-weight: 700; font-size: .2963rem;color: #4a99f6;">{{data.servicePrice}}</span>
				</div>
			</div>
		</div>
	</el-dialog>
</template>
<script>
	import {
		dialogMixin
	} from "@m/dialogMixin"
	import configApi from '@config/configApi'
	export default {
		mixins: [dialogMixin],
		data() {
			return {
				imgBaseUrl: configApi.photoURL,
				data: {},
				editPrice: "",
				orderStas: [{
					name: '全部订单',
					orderStatus: 0
				}, {
					name: '待确认',
					orderStatus: 1
				}, {
					name: '待付款',
					orderStatus: 5
				}, {
					name: '待确认收款',
					orderStatus: 8
				},  {
					name: '待服务',
					orderStatus: 9
				}, {
					name: '待验收',
					orderStatus: 11
				}, {
					name: '待评价',
					orderStatus: 15
				}, {
					name: '已完成',
					orderStatus: 20
				}, {
					name: '已取消',
					orderStatus: 99
				}, ],
				record: this.dialogObj.record,
				tabObj: {
					1: '待确认',
					5: '待付款',
					8: '待确认收款',
					9: '待服务',
					11: '待验收',
					15: '待评价',
					20: '已完成',
					99: '已取消'
				},
			};
		},
		props: {},
		watch: {
			isShow(val) {
				if (val) {
					this.open();
					this.init();
				}
			}
		},
		methods: {
			init() {
				this.record = this.dialogObj.record;
				this.$get("/shop-api/mall/expert-order/" + this.record.id, null).then((res) => {
					if (res.code == 1000) {
						console.log(res);
						this.data = res.data;
						this.editPrice = this.data.servicePrice;
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			confirmOrder() {
				this.$confirm('请认真查看订单服务需求及价格，避免争议', '是否确认接单', {
					dangerouslyUseHTMLString: true,
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'info'
				}).then(() => {
					this.$post("/shop-api/mall/expert-order/price/update", {
						actualPrice: this.editPrice,
						id: this.record.id
					}).then((res) => {
						if (res.code == 1000) {
							this.$post("/shop-api/mall/expert-order/accept", {
								id: this.record.id
							}).then((res2) => {
								if (res2.code == 1000) {
									//刷新表格
									this.$parent.list();
									this.close();
								} else {
									this.$message.error(res2.msg);
								}
							});
						} else {
							this.$message.error(res.msg);
						}
					});
				}).catch(() => {});
			},
			//取消订单
			cancelOrder() {
				this.$prompt('是否取消接单', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消'
				})
					.then(({ value }) => {
						this.$post('/shop-api/mall/expert-order/cancel', {
							id: this.record.id,
							cancelReason: value
						}).then(res => {
							if (res.code == 1000) {
								this.$message.success(res.msg);
								//刷新表格
								this.list();
							} else {
								this.$message.error(res.msg);
							}
						});
					})
					.catch(() => {});
			},
			//打开弹窗
			open() {
				this.formData = {};
			},
			//关闭弹窗
			close() {
				this.visiable = false
			}
		}
	};
</script>
<style lang='scss' scoped>
	* {
		font-size: 12px
	}

	.payInfo-left,
	.payInfo-right {
		>div {
			margin-bottom: 10px;
		}
	}
</style>
